<template>
    <el-container class="body">
        <pub-header  class="el-menu-header"></pub-header>
        <el-container>
            <el-main class="el-main-box">
                <div style="position: absolute; right: 1%; width: 150px;">
                    <el-button type="primary" round @click="changeState" style="">添加用户</el-button>
                </div>
                <el-dialog :visible.sync="dialogVisible" class="submitPutAccount">
                    <el-form label-width="80px" :model="accountInfo" :rules="accountInfo" ref="accountInfo">
                        <el-form-item label="账号">
                        <el-input v-model="accountInfo.username" style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item label="密码">
                        <el-input v-model="accountInfo.password" style="width: 80%;"></el-input>
                        </el-form-item>
                        <el-form-item>
                        <el-button type="primary" @click="submitAccount">提交</el-button>
                        <el-button @click="changeState">取消</el-button>
                        </el-form-item>
                    </el-form>
                </el-dialog>
                <el-empty description="暂无数据" v-if="none_arg"></el-empty>
                <div style="width: 90%; position: absolute; left: 5%; top: 10%">
                    <el-card class="box-card" v-for="item in userList" :key="item.username">
                        <p>账号：{{ item.username }}</p>
                        <br>
                        <p>密码：{{ item.password }}</p>
                        <el-switch
                            style="display: block; margin-top: 15px;"
                            v-model="item.state"
                            active-color="#13ce66"
                            inactive-color="#ff4949"
                            active-text="账号启用"
                            inactive-text="账号停用" @change="changeUserState(item.username, item.state)">
                        </el-switch>
                    </el-card>
                </div>
                <el-row>

                </el-row>
            </el-main>
        </el-container>
    </el-container>
</template>

<script>
import pubHeader from "./header.vue"
export default {
    data(){
        return{
            activeIndex: "4",
            none_arg: false,
            dialogVisible: false,
            accountInfo:{
                "username": "",
                "password": ""
            },
            userList: [
            ]
        }
    },
    components: {pubHeader},
    mounted: function(){
        this.loadAllUser();
    },

    methods: {
        changeState(){
            if (this.dialogVisible){
                this.dialogVisible = false;
            }else{
                this.dialogVisible = true;
            }
            
        },
        loadAllUser(){
            this.$axios.get('/api/hotel/getUserAll').then(res =>{
                if(res.data.state == 200){
                    if(res.data.items.length == 0){
                        this.none_arg = true;
                    }else{
                        this.userList = res.data.items;
                    }
                }else{
                    this.$message.error(res.data.msg);
                }
            })
        },
        changeUserState(value, state){
            console.log(value, state);
            this.$axios.get('/api/hotel/changeOperator', {
                params: {
                    'account': value
                }
            }).then(res =>{
                if(res.data.state == 200){
                    this.$message.success(res.data.msg);
                }else{
                    this.$message.error(res.data.msg);
                    this.loadAllUser
                }
            })
        },
        submitAccount(){
            console.log(this.accountInfo)
            if(this.accountInfo.username && this.accountInfo.password){
                this.$axios({
                    method: "post",
                    url: "/api/hotel/addOperator",
                    headers: {
                        "Content-Type": "application/json"
                    },
                    data: {
                        'username': this.accountInfo.username,
                        'password': this.accountInfo.password
                    }
                }).then(res =>{
                    if(res.data.state == 200){
                        this.$message.success("添加成功");
                        this.dialogVisible = false;
                        this.loadAllUser();
                    }else{
                        this.$message.error(res.data.msg);
                    }
                    this.accountInfo.username = this.accountInfo.password = ''
                })
            }
            
        }

    }
}
</script>

<style scoped>
    .body{
        height: 100%;
        background: #f7f8f9;
    }
    .el-menu-header{
        width: 100%;
    }
    .el-main-box{
        width: 80%;
        height: 85%;
        position: absolute;
        left: 10%;
        top: 8%;
        background: white;
    }
    .box-card{
        width: 30%;
        display: inline-block;
        margin: 1.5%;
    }
</style>
