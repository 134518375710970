<template>
    <el-header id="pub_header"><el-menu
            :default-active.sync="activeIndex"
            mode="horizontal"
            background-color="#545c64"
            text-color="#fff"
            active-text-color="#ffd04b">
            <el-menu-item index="1"><a href="/home" style="text-decoration: none; display: block;">预约中心</a></el-menu-item>
            <el-menu-item index="2"><a href="/work" style="text-decoration: none; display: block;">我的工作台</a></el-menu-item>
            <el-menu-item index="3"><a href="/hotel" style="text-decoration: none; display: block;">酒店管理</a></el-menu-item>
            <el-menu-item index="4"><a href="/account" style="text-decoration: none; display: block;">账号管理</a></el-menu-item>
            <el-menu-item index="5" style="position: absolute; right: 5%;"><a href="/login" style="text-decoration: none; display: block;">登录</a></el-menu-item>
        </el-menu></el-header>
</template>

<script>
export default{
    data(){
        return{
            activeIndex: 1
        }
    }
}
</script>

